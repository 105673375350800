<template>
	<a class="c-article-list__item js-carousel-item" :href="data.Url">
		<div class="c-article-list__item-image">
			<img v-if="data.Image" :src="data.Image" />
			<span v-if="data.Category" class="c-article-list__item-category">
				{{data.Category}}
			</span>
		</div>
		<h2 class="c-article-list__item-heading">{{data.Title}}</h2>
		<div class="c-article-list__item-date">{{formatDate(data.Date)}}</div>
		<div class="c-article-list__item-body">
			<p>{{data.Abstract}}</p>
		</div>
		<div class="c-article-list__item-link-text">
			Learn more
		</div>
	</a>
</template>
<script>
	export default {
		name: 'article-tile',
		props: ['data'],
		data() {
			return {}
		},
		mounted() { },
		methods: {
			formatDate(value) {
				return new Date(value).toLocaleDateString("en-NZ");
			}
		}
	};
</script>
