import $ from 'jquery';

const selectors = {
	container: '.js-footnotes',
	backLink: '.js-footnote__back-link',
	header: '.js-header',
	body: '.js-rte',
};

let $el = {
	$body: $(selectors.body),
	$header: $(selectors.header),
	$container: $(selectors.container)
};

let module = {
	init: function () {
		$el.$body.on('click', 'a', module._linkClick);
		$el.$container.on('click', selectors.backLink, module._backLinkClick);
	},

	_linkClick(event) {

		let $trigger = $(event.currentTarget);

		if ($trigger.attr('href').indexOf('#') !== -1 && $trigger.attr('href') !== '#') {
			let $target = $($trigger.attr('href'));
			if ($target.length) {
				event.preventDefault();
				//console.log({
				//	$trigger,
				//	$target
				//});
				module._scrollToElement($target);
			}
		}
	},

	_backLinkClick(event) {
		event.preventDefault();
		let $trigger = $(event.currentTarget);
		let hash = $trigger[0].hash;
		let $target = $el.$body.find(`a[href="${hash}"]`);
		if ($target.length) {
			module._scrollToElement($target);
		}
	},

	_scrollToElement($target) {
		let targetTop = $target.offset().top;
		let headerHeight = $el.$header.outerHeight();
		let padding = 20;
		let scrollTop = targetTop - headerHeight - padding;
		let param = { scrollTop };

		$('html,body').animate(param, 500);
	}

};

export { module as Footnotes };