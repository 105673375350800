<template>
	<!-- LIST -->
	<div class="c-people-list">
		<transition-group name="list">
			<people-tile v-bind:person="person" v-for="person in data" />
		</transition-group>
	</div>
	<!-- / LIST -->
</template>
<script>
	export default {
		name: 'people-list',
		props: ['data'],
		data() {
			return {}
		},
		mounted() {},
		methods: {}
	};
</script>
