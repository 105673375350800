import $ from 'jquery';
import { publish, subscribe } from 'Util/pubsub';

const selectors = {
	navigation: '.js-tab__navigation',
	contents: '.js-tab__content',
	wrapper: '.js-tab__wrapper',
	left: '.js-tab__scroll-left',
	right: '.js-tab__scroll-right'
};

let $el = {
	$navigation: $(selectors.navigation),
	$contents: $(selectors.contents),
	$wrapper: $(selectors.wrapper),
};

var events = {
	update: '/tab/changed'
};

let module = {
	init: function () {
		$(window).on('load', module._detectOverflow);
		$(window).on('resize', module._detectOverflow);
		$el.$navigation.on('click', 'a', module._showContentClick);
		$el.$navigation.on('click', selectors.left, module._scrollWrapper(-50));
		$el.$navigation.on('click', selectors.right, module._scrollWrapper(50));
	},

	_showContentClick(event) {
		event.preventDefault();
		let $trigger = $(event.currentTarget);
		let $target = $el.$contents.filter($trigger.attr('href'));

		$el.$contents.attr('aria-current', false);
		$target.attr('aria-current', true);

		$el.$navigation.find('[aria-current=true]').attr('aria-current', false);
		$trigger.attr('aria-current', true);

		history.pushState({}, '', $trigger.attr('href'));

		publish(events.update, $target);
	},

	_detectOverflow(event) {
		if ($el.$wrapper.length > 0) {
			if ($el.$wrapper[0].scrollWidth > $el.$wrapper[0].clientWidth) {
				$el.$navigation.addClass('Scrolling');
			} else {
				$el.$navigation.removeClass('Scrolling');
			}
		}
	},

	_scrollWrapper(amount) {
		return function (event) {
			event.preventDefault();
			$el.$wrapper[0].scrollBy(amount, 0);
		}
	}

};

export { module as PeopleTabs };
