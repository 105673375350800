<template>

	<div class="c-insight-news-wrapper">
		<!-- LIST -->
		<div class="c-insight-list">
			<article-tile v-bind:data="item" v-for="item in Items" />
		</div>

		<!-- PAGINATION -->
		<div class="pagi" v-if="Meta">
			<button class="pagi-more" :disabled="Meta.HasNextPage === false" @click="getPage(filterState.page + 1)">Load more</button>
		</div>
	</div>

</template>
<script>
	import DataSource from 'Vue/components/article-listing-data.js';

	export default {
		name: 'personal-insights-list',
		props: ['data'],
		data() {
			return {
				filterState: {
					page: 1,
				},
				Items: [], // object - reduced set of people items used for render
				Meta: null
			}
		},
		mounted() {
			//console.log(this.data);
			//this.filterState = DataSource.getFilterState();
			this.mount(this.data);
			DataSource.init(this.data.Api);
		},
		methods: {
			mount(data) {
				//this.Items = data.Items;
				this.Meta = data.Meta;
				this.filterState.page = data.Meta.PageNumber;

				for (let i = 0, l = data.Items.length; i < l; i++) {
					this.Items.push(data.Items[i]);
				}

				//console.log({
				//	method: 'mount',
				//	data,
				//	pages: this.pages
				//});
			},
			apply() {
				let result = DataSource.apply(JSON.parse(JSON.stringify(this.filterState)), false);
				let mount = this.mount;

				result.then(function (data) {
					//console.log(data);
					mount(data);
				});
			},
			getPage(num) {
				this.filterState.page = num;
				this.apply();
			}
		}
	};
</script>
