<template>
	<a class="c-people-list__item" :href="person.Url">
		<div class="c-people-list__item-image-wrapper">
			<img class="c-people-list__item-image" v-if="person.Image" :src="person.Image + '?width=300'" />
		</div>
		<div class="c-people-list__item-wrapper">
			<h2 class="c-people-list__item-heading">{{person.Name}}</h2>
			<div class="c-people-list__item-role" v-if="person.Role">{{person.Role}}</div>
			<div class="c-people-list__item-title" v-if="person.PositionTitle">{{person.PositionTitle}}</div>
		</div>
	</a>
</template>
<script>
	export default {
		name: 'people-tile',
		props:['person'],
		data() {
			return {}
		},
		mounted() {},
		methods: {}
	};
</script>
