import Flickity from 'flickity';
import 'flickity-fade';
import 'flickity-imagesloaded';

let $homeHeroRotators;

const arrowShape = {
	x0: 10,
	x1: 60, y1: 50,
	x2: 65, y2: 45,
	x3: 20
};

const homeHeroRotatorSettings = {
	arrowShape: arrowShape,
	wrapAround: true,
	lazyLoad: true,
	pageDots: true,
	fade: true
};

var HomeHeroRotator = {
	init: function($elms) {

		$homeHeroRotators = $elms;

		for (let i = 0; i < $homeHeroRotators.length; i++) {
			HomeHeroRotator._initFlickity($homeHeroRotators[i]);
		}
	},

	_initFlickity: function(homeHeroRotator) {
		const thisFlick = new Flickity(homeHeroRotator, homeHeroRotatorSettings);
	}
};

export { HomeHeroRotator };
