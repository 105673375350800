const selectors = {
	trigger: '.js-person-vcard',
};

const attr = {
	name: 'data-name',
	email: 'data-email',
	org: 'data-org',
	role: 'data-role',
	photo: 'data-photo',
	phone: 'data-phone',
	mobile: 'data-mobile',
}

var module = {
	init: function () {
		let triggers = document.querySelectorAll(selectors.trigger);
		if (triggers.length) {
			for (let i = 0, l = triggers.length; i < l; i++) {
				module._initTrigger(triggers[i]);
			}
		}
	},
	_initTrigger: function(elm) {
		let data = {
			name: elm.getAttribute(attr.name) || '',
			role: elm.getAttribute(attr.role) || '',
			org: elm.getAttribute(attr.org) || '',
			email: elm.getAttribute(attr.email) || '',
			phone: elm.getAttribute(attr.phone) || '',
			mobile: elm.getAttribute(attr.mobile) || '',
		};

		let parts = [
			`BEGIN:VCARD`,
			`VERSION:4.0`,
			`FN:${data.name}`,
			data.role.length ? `ROLE:${data.role}` : null,
			data.org.length ?`ORG:${data.org}` : null,
			data.email.length ?`EMAIL;TYPE=work:${data.email}` : null,
			data.mobile.length ?`TEL;TYPE=cell:${data.mobile}` : null,
			data.phone.length ?`TEL;TYPE=work:${data.phone}` : null,
			`END:VCARD`
		];
		parts = parts.filter(x => x !== null);

		let vcard = parts.join('\r\n');
		let href = 'data:text/x-vcard;charset=utf8,' + vcard;

		elm.setAttribute('href', href);

		//console.log({
		//	data,
		//	vcard,
		//	elm,
		//	href
		//});
	}
};

export { module as Vcard };

// vcf: text/x-vcard
/*
BEGIN:VCARD
VERSION:4.0
FN:{name}
ROLE:{role}
ORG:{org};{role}
EMAIL;TYPE=work:{email}
PHOTO;MEDIATYPE=image/jpeg:{photo}
TEL;TYPE=cell:{mobile}
TEL;TYPE=voice:{phone}
END:VCARD
*/