import $ from 'jquery';
import { createApp } from 'vue/dist/vue.esm-bundler.js'

// ## Vue Modules
import expertiseListingModule from 'Vue/components/expertise-listing/expertise-listing';
import articleTileModule from 'Vue/components/article-tile/article-tile';
import insightListingModule from 'Vue/components/insight-listing/insight-listing';
import newsListingModule from 'Vue/components/news-listing/news-listing';
import peopleListingModule from 'Vue/components/people-listing/people-listing';
import peopleTileModule from 'Vue/components/people-tile/people-tile';
import personalInsightsListingModule from 'Vue/components/personal-insights-list/personal-insights-list';
import peopleListControlModule from 'Vue/components/people-list-control/people-list-control';
import articleListControlModule from 'Vue/components/article-list/article-list';

// ## Vue css selectors for containers of modules
const selectors = {
	peopleListingComponent: '#people-listing',
	expertiseListingComponent: '#expertise-listing',
	insightListingComponent: '#insight-listing',
	newsListingComponent: '#news-listing',
	personalInsightsListingComponent:'#personal-insights-list',
	peopleListControl: '.vue-people-list-control',
	articleListControl: '.vue-article-list-control',
};

// ## Vue element tags for registration
const moduleTags = {
	peopleListing: 'people-listing',
	peopleTile: 'people-tile',
	expertiseListing: 'expertise-listing',
	insightListing: 'insight-listing',
	newsListing: 'news-listing',
	articleTile: 'article-tile',
	personalInsightsListing: 'personal-insights-list',
	peopleListControl: 'people-list-control',
	articleListControl: 'article-list',
};

let module = {
	init: function() {
		module._initComponents();
		module._initControls();
	},
	_initComponents: function () {

		// People Listing
		const peopleListingComponent = createApp({});
		peopleListingComponent.component(moduleTags.peopleListing, peopleListingModule);
		peopleListingComponent.component(moduleTags.peopleTile, peopleTileModule);
		if ($(selectors.peopleListingComponent).length) {
			peopleListingComponent.mount(selectors.peopleListingComponent);
		}

		// Expertise Listing
		const expertiseListingComponent = createApp({});
		expertiseListingComponent.component(moduleTags.expertiseListing, expertiseListingModule);
		if ($(selectors.expertiseListingComponent).length) {
			expertiseListingComponent.mount(selectors.expertiseListingComponent);
		}

		// Insight Listing
		const insightListingComponent = createApp({});
		insightListingComponent.component(moduleTags.insightListing, insightListingModule);
		insightListingComponent.component(moduleTags.articleTile, articleTileModule);
		if ($(selectors.insightListingComponent).length) {
			insightListingComponent.mount(selectors.insightListingComponent);
		}

		// News Listing
		const newsListingComponent = createApp({});
		newsListingComponent.component(moduleTags.newsListing, newsListingModule);
		newsListingComponent.component(moduleTags.articleTile, articleTileModule);
		if ($(selectors.newsListingComponent).length) {
			newsListingComponent.mount(selectors.newsListingComponent);
		}

		// Personal Insights Listing
		const personalInsightsListingComponent = createApp({});
		personalInsightsListingComponent.component(moduleTags.personalInsightsListing, personalInsightsListingModule);
		personalInsightsListingComponent.component(moduleTags.articleTile, articleTileModule);
		if ($(selectors.personalInsightsListingComponent).length) {
			personalInsightsListingComponent.mount(selectors.personalInsightsListingComponent);
		}
	},
	_initControls: function () {

		// People List Control
		let peopleListControls = document.querySelectorAll(selectors.peopleListControl);
		if (peopleListControls.length) {
			for (let i = 0, l = peopleListControls.length; i < l; i++) {
				let key = peopleListControls[i].getAttribute('data-key');
				let component = createApp({});
				component.component(moduleTags.peopleListControl, peopleListControlModule);
				component.component(moduleTags.peopleTile, peopleTileModule);
				component.mount(`${selectors.peopleListControl}[data-key="${key}"]`);
			}
		}

		// Article List Control
		let articleListControls = document.querySelectorAll(selectors.articleListControl);
		if (articleListControls.length) {
			for (let i = 0, l = articleListControls.length; i < l; i++) {
				let key = articleListControls[i].getAttribute('data-key');
				let component = createApp({});
				component.component(moduleTags.articleListControl, articleListControlModule);
				component.component(moduleTags.articleTile, articleTileModule);
				component.mount(`${selectors.articleListControl}[data-key="${key}"]`);
			}
		}
	}
};

module.init();