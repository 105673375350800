<template>
	<!-- FILTER -->
	<div class="c-insight-news__filter">
		<div class="c-insight-news__filter-wrapper">
			<fieldset class="c-news-filter">
				<div class="c-news-filter__option">
					<label for="categories">Filter by:</label>
					<button :class="{'is-selected':filterState.category === 0}" @click="setCategory(0)">All</button>
					<button :class="{'is-selected':filterState.category === filter.Key}" @click="setCategory(filter.Key)" v-for="filter in data.Filters">{{filter.Value}}</button>
				</div>
			</fieldset>
		</div>
	</div>

	<div class="c-insight-news-wrapper">
		<!-- SUMMARY -->
		<div class="c-insight-news__summary" v-if="Meta">Showing {{Meta.FirstItemOnPage}} to {{Meta.LastItemOnPage}} of {{Meta.TotalItemCount}} results.</div>

		<!-- LIST -->
		<div class="c-news-list">
			<article-tile v-bind:data="item" v-for="item in Items" />
		</div>

		<!-- PAGINATION -->
		<div class="pagi" v-if="pages.length">
			<button class="pagi-button pagi-prev" :disabled="Meta.HasPreviousPage === false" @click="setPage(filterState.page - 1)">
				<span class="iconf-arrow-left"></span>
				<span class="c-pagination__text">Previous</span>
			</button>
			<button class="pagi-num" :class="{ 'is-selected' : filterState.page === page }" @click="setPage(page)" v-for="page in pages">{{page}}</button>
			<button class="pagi-button pagi-next" :disabled="Meta.HasNextPage === false" @click="setPage(filterState.page + 1)">
				<span class="c-pagination__text">Next</span>
				<span class="iconf-arrow-right"></span>
			</button>
		</div>
	</div>

</template>
<script>
	import DataSource from 'Vue/components/article-listing-data.js';

	export default {
		name: 'news-listing',
		props: ['data'],
		data() {
			return {
				filterState: {
					category: 0,
					page: 1,
				},
				pages: [],
				Items: [], // object - reduced set of people items used for render
				Meta: null,
				currentSelections: [] // object - current filters in use can be removed individualy
			}
		},
		mounted() {
			this.filterState = DataSource.getFilterState();
			this.mount(this.data);
			DataSource.init(this.data.Api);
		},
		methods: {
			mount(data) {
				this.Items = data.Items;
				this.Meta = data.Meta;
				this.filterState.page = data.Meta.PageNumber;

				let pages = [];
				let start = Math.max(1, Math.min(data.Meta.PageCount - 4, data.Meta.PageNumber - 2));
				let end = Math.min(data.Meta.PageCount, Math.max(data.Meta.PageNumber + (5 - data.Meta.PageNumber), data.Meta.PageNumber + 2));
				for (let i = start; i <= end; i++) {
					pages.push(i);
				}
				this.pages = pages;
				if (this.pages.length < 2) {
					this.pages = [];
				}
			},
			setCategory(key) {
				if (this.filterState.category === key) {
					this.filterState.category = 0;
				} else {
					this.filterState.category = key;
				}
				this.filterState.page = 1;
				this.apply();
			},
			apply() {
				let result = DataSource.apply(JSON.parse(JSON.stringify(this.filterState)));
				let mount = this.mount;

				result.then(function (data) {
					//console.log(data);
					mount(data);
				});
			},
			setPage(num) {
				this.filterState.page = num;
				this.apply();

				document.getElementById('news-listing').scrollIntoView({ behavior: 'smooth' })
			}
		}
	};
</script>
