<template>
	<div class="c-expert">

		<!-- FILTER -->
		<div class="c-expert__filter-wrapper">
			<fieldset class="c-expert__filter c-form">
				<div class="c-expert__filter__option">
					<input class="c-expert__filter-searchbox" id="keyword" placeholder="Type name" list="expertise-names" v-model="keyword" type="search" />
					<span class="iconf-search c-expert__filter__option-search-icon"></span>
					<datalist id="expertise-names">
						<option v-for="item in data.Items" :value="item.Name"></option>
					</datalist>
				</div>
				<div class="c-expert__filter__option">
					<label>Sort by</label>
					<button class="c-expert__filter-button" :class="{ 'is-selected' : isActive(0) }" @click="addSelection(0)">All</button>
					<button class="c-expert__filter-button" v-for="option in data.Filters" :class="{ 'is-selected' : isActive(option.Key) }" @click="addSelection(option.Key)">{{option.Value}}</button>
				</div>
			</fieldset>
		</div>

		<!-- LIST -->
		<div class="c-expert__list-wrapper">
			<div class="c-expert__list-container">
				<div class="c-expert__list">
					<a :href="item.Url" class="c-expert__list-item" v-for="item in filterItems(data.Items)">
						<div>{{item.Name}}</div>
						<div class="c-expert__list-item-icon">
							<span class="iconf-arrow-right"></span>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
	export default {
		name: 'expertise__listing',
		props: ['data'],
		data() {
			return {
				keyword: null,
				filteredItems: [], // object - reduced set of people items used for render
				currentSelections: [0] // object - current filters in use can be removed individualy
			}
		},
		mounted() {

		},
		methods: {
			addSelection(id) {
				if (id === 0) {
					this.currentSelections = [0];
				} else {
					var current = JSON.parse(JSON.stringify(this.currentSelections));
					current = current.filter(x => x !== 0);

					if (this.isActive(id)) {
						current = current.filter(x => x !== id);
						if (current.length === 0) {
							current = [0];
						}
					} else {
						//current.push(id); // - allow multiple selections
						current = [id];
					}
					this.currentSelections = current;
				}
			},
			isActive(id) {
				return this.currentSelections.indexOf(id) !== -1;
			},
			filterItems(items) {

				if (this.keyword?.length > 0) {
					items = items.filter(x => x.Name.toLowerCase().includes(this.keyword.toLowerCase()) === true);
				}

				if (this.currentSelections.indexOf(0) === -1) {
					for (let i = 0, l = this.currentSelections.length; i < l; i++) {

						let option = this.currentSelections[i];
						items = items.filter(x => x.Categories.indexOf(option) >= 0);
					}
				}

				return items;
			}
		}
	};
</script>
