/* app/controls/control.testimonials-rotator */

import $ from 'jquery';
import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fade';
import 'flickity-imagesloaded';
import { subscribe } from 'Util/pubsub';

const selectors = {
	rotator: '.js-testimonials-rotator',
};

const dataSelectors = {
	flickity: 'flickity-instance'
};

const flickitySettings = {
	arrowShape: {
		x0: 10,
		x1: 60, y1: 50,
		x2: 65, y2: 45,
		x3: 20
	},
	wrapAround: true,
	lazyLoad: true,
	pageDots: false
};

var module = {
	init: function($elms) {

		for (let i = 0; i < $elms.length; i++) {
			module._initFlickity($elms.eq(i));
		}

		subscribe('/tab/changed', module._reset);
		// subscribe('/window/resize', module._reset);
	},

	_initFlickity: function($rotator) {
		const thisFlick = new Flickity($rotator[0], flickitySettings);
		$rotator.data(dataSelectors.flickity, thisFlick);
	},

	_reset: function ($container) {
		$container = $container || $(document);
		let $rotators = $container.find(selectors.rotator);

		for (let i = 0; i < $rotators.length; i++) {
			let $rotator = $rotators.eq(i);
			let flick = $rotator.data(dataSelectors.flickity);
			if (flick?.resize) {
				flick.resize();
			}
		}
	}
};

export { module as TestimonialsRotator };
