<template>
	<section class="c-latest-insights">
		<div class="o-layout-wrapper">
			<header>
				<h2 v-if="!!heading" class="c-latest-insights__heading">{{heading}}</h2>
				<a v-if="!!url" class="c-latest-insights__link" :href="url">View All Insights<span class="iconf-arrow-right"></span></a>
			</header>
			<div class="c-latest-insights__item-list js-pathway-carousel">
				<article-tile v-bind:data="item" v-for="item in data" />
			</div>
		</div>
	</section>
</template>
<script>
	export default {
		name: 'article-list',
		props: ['heading', 'url', 'data'],
		data() {
			return {}
		},
		mounted() { },
		methods: {}
	};
</script>
