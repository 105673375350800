import $ from 'jquery';
import { debounce } from 'Util/debounce';

const selectors = Object.freeze({
	select: '.js-select-link'
});

const module = {
	init: function () {
		module._initEvents();
	},

	_initEvents: function () {

		let selectContainer = document.querySelector(".select-container");
		let select = document.querySelector(".select");
		let input = document.getElementById("input");
		let options = document.querySelectorAll(".select-container .option");

		select.onclick = () => {
			selectContainer.classList.toggle("active");
		};

		options.forEach((e) => {
			e.addEventListener("click", () => {
				input.value = e.innerText;
				selectContainer.classList.remove("active");
				options.forEach((e) => {
					e.classList.remove("selected");
				});
				e.classList.add("selected");

				const $selected = $(".option.selected");

				module._change($selected);
			});
		});

		document.addEventListener('click', event => {
			const isClickInside = selectContainer.contains(event.target)

			if (!isClickInside) {
				selectContainer.classList.remove("active");
			}
		});
	},

	_change: debounce(function ($selected) {
		const value = $selected.attr('data-val');
		document.location = value;
	}, 500)
};

const selectLink = {
	init: module.init
};

export { selectLink };
