import $ from 'jquery';

const selectors = {
	container: '.js-jumpbar__container',
	links: '.js-jumpbar__link',
	header: '.js-header',
	sections: '.c-jump-target',

	sticky: '.stick'
};

let $el = {
	$header: $(selectors.header),
	$container: $(selectors.container)
};

let headerHeight;
let container;
let currentPos;
let sections;
let links;

var module = {
	init: function () {
		if ($el.$container.length) {
			module._initVariables();
			module._initStickyBar();
			$el.$container.on('click', selectors.link, module._linkClick);
		}
	},
	_initStickyBar: function () {

		module._active();
		module._stick();

		window.onscroll = function () {
			module._stick();
		}
	},

	_initVariables: function () {
		headerHeight = $el.$header.outerHeight();
		container = document.querySelector(selectors.container);
		currentPos = container.getBoundingClientRect().top + window.pageYOffset;
		sections = document.querySelectorAll(selectors.sections);
		links = document.querySelectorAll(selectors.links);
	},

	_stick: function () {
		if (window.pageYOffset + headerHeight > currentPos) {

			container.classList.add(selectors.sticky.replace('.', ''));

			var current = "";

			sections.forEach((section) => {
				const sectionTop = section.offsetTop;
				if (pageYOffset >= sectionTop - 200) {
					current = section.getAttribute("id");
				}
			});

			links.forEach((a) => {
				a.classList.remove("active");
				if (a.classList.contains(current)) {
					a.classList.add("active");
				}
			});

		} else {

			container.classList.remove(selectors.sticky.replace('.', ''));
			module._active();
		}
	},

	_active: function () {
		var firstLink = links[0];
		$(firstLink).addClass('active');
	},

	_linkClick: function (event) {
		event.preventDefault();
		let $target = $($(event.target)[0].hash);
		let targetTop = $target.offset().top;
		let headerHeight = $el.$header.outerHeight();
		let containerHeight = $el.$container.outerHeight();
		let offsetHeight = headerHeight + containerHeight;
		let scrollTop = targetTop - offsetHeight;

		if ($el.$container.is(selectors.sticky) === false) {
			scrollTop -= containerHeight;
		}

		let param = { scrollTop };

		$('html,body').animate(param, 500);
	}
};

export { module as Jumplinks };